#ru #header .navbar-nav li {
    font-size: 0.9em;
    font-weight: 400;
}
#ru .info.team .tild {
    left: -150px;
    top: 330px;
    letter-spacing: 10px;
}
#ru .info .services li p {
    font-weight: 400;
}
#ru .info .pricing .month {
    font-size: 1em;
    font-weight: 400;
}
#ru .info .more {
    font-weight: 400;
}
#ru .info.testimonial {
    bottom: 30px;
}
#ru .info.work .tild {
    top: 260px;
}
#ru .info .slider blockquote q {
    font-weight: 400;
}
#ru .info.team .more {
    font-size: 0.8em;
    font-weight: 400;
}

#ru .f1 .footer-txt .text p,
#ru .f1 .footer-txt #ru .f1 .contact,
#ru .f1 .contact address,
#ru .f1 .contact a,
#ru .f1 .contact span,
#ru #footer .copyright,
#ru #footer .sub-nav li a {
    font-weight: 100;
}
#ru .info.work .more {
    bottom: 30px;
}
#ru .info.price .tild {
    left: 135px;
    top: 140px;
}
#ru .info .more {
    width: max-content;
    font-size: 0.9em;
}
@media only screen and (max-width: 1599px) {
    #ru .info.about .tild {
        right: 110px;
    }
    #ru .info.team .tild {
        left: -170px;
        letter-spacing: 10px;
        top: 330px;
    }
    #ru .info.team .more {
        bottom: 10px !important;
    }
}

@media (max-width: 1279px) {
    #ru .info .txt-frame .img-holder h3 {
        font-size: 1em;
        font-weight: 600;
    }
    #ru .info.team .tild {
        left: -90px !important;
        top: 220px !important;
        font-size: 1.3em;
        letter-spacing: 5px;
    }
    #ru .info.testimonial .tild {
        right: -25px;
    }
    #ru .info .txt-frame .img-holder .designation {
        font-size: 0.7em;
        font-weight: 100;
        color: #444;
    }
}
@media (max-width: 1023px) {
    #ru .info.work .txt-frame .filter {
        font-weight: 100;
    }
    #ru .info .pricing .month {
        font-weight: 100;
        font-size: 0.7em;
        font-family: serif;
    }
}
@media (max-width: 767px) {
    #ru .info .slider blockquote .designation {
        font-weight: 100;
        margin-top: 10px;
    }
}
#ru .info .pricing .package {
    font-family: serif;
}
